import React, {useState, useEffect, useRef} from 'react';
import { TwitterPicker } from 'react-color';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
const colors = ['#000000', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#FCC400', '#B0BC00', '#68BC00', '#009CE0', '#7B64FF', '#FA28FF', '#9F0500', '#0C797D', '#0062B1', '#653294', '#AB149E']
function App() {
  const [pic, setPic ] = useState();
  const [picUrl, setPicUrl ] = useState('https://unavatar.vercel.app/twitter/jiten_bansal');
  const [fontSize, setFontSize ] = useState(11);
  const [bgColor, setBgColor ] = useState('#000000');
  const [code, setCode ] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const previewRef = useRef(null);

  useEffect(() => {
    fetch("https://unpkg.com/pattern.css@1.0.0/dist/pattern.min.css")
      .then(res => res.text())
      .then(
        (result) => {
          setCode(result.replace(/\s/g,''));
        }
      )
  }, []);

  useEffect(() => {
    if(pic && pic.size > 10000) {
      console.log(pic);
      setPicUrl(URL.createObjectURL(pic));
    }
  },[pic]);

  const download = () => {
    setShowPopup(true);
    setShowSidebar(false);
    previewRef.current.scrollIntoView();
  }
  const handleColorChange =(color) => {
    setBgColor(color.hex);
  }
  return (
      <>
        <div className="header">
        <div className="logo text-warning">Devatar</div>
        <p className="text-light">Generate your profile picture from your own code</p>
        <button className="btn btn-warning mx-1 btn-sm" onClick={() => setShowSidebar(true)}>START</button>
        <p className="my-4 text-light"> made by <a className="text-warning" href="https://bansal.io/?ref=devatar">bansal</a></p>
        </div>
         {showSidebar && <div className="sidebar scroll">
            <button className="hide" onClick={()=> setShowSidebar(!showSidebar)}>×</button>
            
            <div className="dark-card">
            <label className="label">Upload Profile Picture</label>
            <input
              type="file"
              onChange={(e) => setPic(e.target.files[0])}
            />
            </div>
            <div className="dark-card">
            <label className="label">Paste Your Code</label>
            <textarea
              rows="5"
              className="form-control scroll"
              onChange={(e) => setCode(e.target.value.replace(/\s/g,''))}
              value={code}
            ></textarea>
            </div>
            <div className="dark-card">
            <label className="label">Change Font Size</label>
            <input
              type="range"
              min="5"
              max="25"
              step="1"
              className="form-range"
              value={fontSize}
              onChange={(e) => setFontSize(e.target.value)}
            />
            </div>
            <div className="dark-card">
            <label className="label">Background Color</label>
            <TwitterPicker
              triangle="hide"
              colors={colors}
              color={ bgColor }
              onChange={ handleColorChange }
            />
            </div>
            <div className="text-center">
              <button className="btn btn-secondary btn-block" onClick={download}>Download Pic</button>
            </div>
            <p className="text-white text-center mt-5">
              Use <a href="https://twitter.com/hashtag/devatar" rel="noreferrer" target="_blank" className="text-warning">#devatar</a> on twitter to support this product.
            </p>
          </div>
        }
          <div className="preview-container" style={{ backgroundColor: bgColor }}><div
            ref={previewRef}
            className="preview"
            style={{ backgroundImage: `url(${picUrl})`, fontSize: `${fontSize}px`}}
          >
            {code}
          </div>
          </div>
          <div className="modal" tabIndex="-1" style={{display: showPopup ? 'block' : 'none'}}>
            <div className="modal-dialog rounded">
              <div className="modal-content bg-dark rounded">
                <div className="modal-body text-center text-light">
                  <p>To download the picture, take a screenshot and crop the picture.
                  <br/> Or
                  <br/> Use your favourite snipping tool.</p>
                  <button type="button" className="btn btn-light btn-sm mt-4" data-bs-dismiss="modal" onClick={()=> setShowPopup(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
      </>
  );
}

export default App;
